
import { apiModelModelApplyExampleLists, apiModelModelApplyExampleGetStatus, apiModelModelApplyExampleDel } from '@/api/model'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Task extends Vue {
    // 分页
    pager: RequestPaging = new RequestPaging()
    apiModelModelApplyExampleLists = apiModelModelApplyExampleLists
    form: any = {
        id: ''
    }
    applyList = []
    statusList = []

    queryObj:any = {
        status: -10
    }


    reset() {
        this.queryObj = {
            status: -10,
            shop_id: ''
        }
        this.getList()
    }

    async onDel(id: any): Promise<void> {
        await apiModelModelApplyExampleDel({ id: id })
        this.getList()
    }
    
    // 获取列表
    async getList() {
        this.pager
            .request({
                callback: apiModelModelApplyExampleLists,
                params: {
                    ...this.queryObj
                }
            })
            .then(res => {
                this.pager.lists.forEach((v: any) => {
                    this.statusList.forEach((v1: any) => {
                        if (v.status == v1.key) {
                            this.$set(v, 'status_ch', v1.value)
                            this.$set(v, 'status_color', v1.color)
                        }
                    })
                })
            })
    }

    // 新增
    goTaskAdd() {
        this.$router.push({
            path: '/model/template_edit',
            query: {
                mode: PageMode['ADD']
            }
        })
    }

    // 编辑
    goTaskEdit(id: number) {
        this.$router.push({
            path: '/model/template_edit',
            query: {
                id: id as any,
                mode: PageMode['EDIT']
            }
        })
    }

    // 获取状态
    getStatus() {
        apiModelModelApplyExampleGetStatus({})
            .then((res: any) => {
                this.statusList = res
                this.getList()
            })
            .catch(() => {})
    }

    created() {
        this.getStatus()
    }
}
